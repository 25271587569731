<template>
  <div class="animated fadeIn">
    <!--
    <b-button variant="danger" :active="true" @click="Monthly()">Monthly</b-button>
    <b-button variant="primary" :active="true" @click="Weekly()">Weekly</b-button>
    <b-button variant="success" :active="true" @click="Daily()">Daily</b-button>
    -->
    <b-card :title="$t('message.title.incomePerMc')">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t("message.year") }} :
          <br />
          <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.machineId') }} :
          <br />
          <model-list-select :list="machineData" v-model="searchData.id" option-value="machineId" option-text="name"
            placeholder="Select Machine ID"></model-list-select>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.date') }} :
          <br />
          <!-- <date-picker range type="datetime" v-model="date" lang="en" format="yyyy-MM-dd"  width=220></date-picker> -->
          <div class="table-responsive">
            <date-time-picker v-model="searchData.date"></date-time-picker>
          </div>

          <!-- <list-select :list="machineData" :selected-item="selectedId" placeholder="Select Machine ID" option-value="machineId" option-text="machineId" @select="onSelect" />-->
        </div>
        <!--<div class="col-sm-6 col-md-3"> ประเภทการค้นหา :  <b-form-select v-model="searchData.by" :options="by_select"/></td> </div>-->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 my-1">  
          <br />
          <b-button class="col-5 col-sm-5" v-on:click="searchFn({ excel: false })" variant="primary">
            <i class="fa fa-search"></i>
            &nbsp;{{ $t('message.search') }}
          </b-button> &nbsp;
          <b-button class="col-5 col-sm-5 " v-on:click="ExportFn(rowData)" variant="success">
            <!-- <b-button class="col-5 col-sm-4 ml-1 mr-1" v-on:click="searchFn({ excel: true })" variant="success"> -->
            <i class="icon-docs"></i>
            &nbsp;{{ $t('message.export') }}
          </b-button>

          <!-- <download-excel  class   = "btn btn-success" @click = "test()" :title = "title"  :data   = "rowData" :fields = "exportField" :name    = "reportFilename"> Export <i class="icon-docs"></i> </download-excel>-->
        </div>

      </div>
      <div class="wrapper">
        <br />
        <b-list-group-item>
          <div class="col-12">
            <div class="table-responsive" style="top:15px;">
              <br />
              <table class="table table-striped" v-if="rowData !== ''">
                <thead>
                  <tr>
                    <!-- <th>{{ $t('message.No') }}</th> -->
                    <th> {{ $t('message.productId') }} </th>
                    <th>{{ $t('message.productName') }}</th>
                    <th style="text-align: right"> {{ $t("message.transactions") }} </th>
                    <th style="text-align: right"> {{ $t('message.sale') }} </th>
                    <th style="text-align: right"> {{ $t('message.payByCash') }}</th>
                    <th style="text-align: right"> {{ $t('message.payByQr') }} </th>
                    <!-- <th style="text-align: right"> {{ $t('message.payByCreditBalance') }} </th> -->
                    <th style="text-align: right"> {{ $t('message.usedCreditBalance') }}</th>
                    <th style="text-align: right"> {{ $t('message.topupCredit') }} </th>
                  </tr>
                </thead>
                <!-- {{rowData}} -->
                <template v-for="item in rowData">
                  <tr v-if="item.product">
                    <!-- <td>{{item.index}}</td> -->
                    <td>{{ item.productId }}</td>
                    <td>{{ item.product.name }}</td>
                    <td style="text-align: right">{{ webServices.addCommas(item.qty) }}</td>
                    <td style="text-align: right">{{ webServices.addCommas(item.price) }}</td>
                    <td style="text-align: right">{{ webServices.addCommas(item.acceptorMoney) }}</td>
                    <td style="text-align: right">{{ webServices.addCommas(item.qrPayment) }}</td>
                    <!-- <td style="text-align: right">{{webServices.addCommas(item.useCredit - item.topUpCreate)}}</td> -->
                    <td style="text-align: right">{{ webServices.addCommas(item.useCredit) }}</td>
                    <td style="text-align: right">{{ webServices.addCommas(item.topUpCreate) }}</td>
                  </tr>
                </template>

                <tfoot>
                  <tr v-if="rowData != ''">
                    <th colspan="1"></th>
                    <th>{{ $t('message.sum') }}</th>
                    <th style="text-align: right">{{ webServices.addCommas(totalQty) }}</th>
                    <th style="text-align: right">{{ webServices.addCommas(totalPrice) }}</th>
                    <th style="text-align: right">{{ webServices.addCommas(totalAcceptorMoney) }}</th>
                    <th style="text-align: right">{{ webServices.addCommas(totalQrPayment) }}</th>
                    <!-- <th style="text-align: right">{{webServices.addCommas(totalDiffCredit)}}</th> -->
                    <th style="text-align: right">{{ webServices.addCommas(totalUseCredit) }}</th>
                    <th style="text-align: right">{{ webServices.addCommas(totalTopUpCreate) }}</th>
                  </tr>
                </tfoot>
              </table>
              <template v-else>
                <p style="text-align: center">{{ $t('message.transactionNotFound') }}</p>
              </template>
              <!-- <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
            </div>
          </div>
        </b-list-group-item>
      </div>
    </b-card>
    <b-modal ref="exportModal" :title="$t('message.modalTitle.summary')" @hidden="closeExportModal()">
      <table class="table table no-border table-responsive table-sm">
        <tr>
          <td>{{ $t('message.date') }}</td>
          <td>{{ searchData.start }} - {{ searchData.end }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.machineId') }}</td>
          <td>{{ searchData.id }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.transactions') }}</td>
          <td>{{ totalQty }}</td>
        </tr>
        <tr>
          <td>{{ $t('message.sale') }}</td>
          <td>{{ totalPrice }}</td>
        </tr>
      </table>
      <div slot="modal-footer" class="w-100">
        <!-- <download-excel class="btn btn-success float-right" :title="title" :data="exportData" :fields="exportField" :name="reportFilename">
          Export
          <i class="icon-docs"></i>
        </download-excel> -->

        <b-btn class="btn btn-success float-right" v-on:click="searchFn({ excel: true })" variant="success">
          <i class="fa fa-searchFn"></i>
          &nbsp;{{ $t("message.export") }}
        </b-btn>
        <!-- <b-btn class="col-sm-12 col-md-4 ml-12 my-2 mr-1" v-on:click="searchFn({ excel: true })" variant="success" v-if="$isRole('owner', role_id)">
          <i class="fa fa-download"></i>
          &nbsp;{{ $t("message.export") }}
        </b-btn> -->
        <!-- <b-btn variant="danger" class="float-left" @click="closeExportModal()">Close</b-btn>  -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import webServices from '../../script'
// import DatePicker from '../../custom_modules/vue2-datepicker'
// import DatePicker from 'vue2-datepicker'
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
import FileSaver from 'file-saver'
import 'vue2-datepicker/index.css'

import { ListSelect, ModelListSelect } from '../../custom_modules/search-select'
import moment from 'moment'

export default {
  name: 'SalesByMachine',
  components: {
    // DatePicker,
    DateTimePicker,
    ListSelect,
    ModelListSelect
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      totalRows: 1,
      machineData: [],
      rowData: [],
      rowDataQrPayment: [],
      searchData: {
        by: 'product',
        id: '',
        date: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      },
      data_export: {
        id: '',
        date: []
      },
      totalQty: 0,
      totalPrice: 0,
      totalAcceptorMoney: 0,
      totalQrPayment: 0,
      totalUseCredit: 0,
      totalTopUpCreate: 0,
      totalDiffCredit: 0,

      selectedId: {},
      by_select: [
        { value: 'product', text: 'product' },
        { value: 'slot', text: 'slot' }
      ],
      exportField: {
        รหัสสินค้า: 'productId',
        sku: 'product.sku',
        ชื่อสินค้า: 'product.name',
        จำนวน: 'qty',
        ยอดขาย: 'price'
      },
      webServices: webServices,
      title: [],
      reportFilename: '',
      exportData: []
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },
    getMachine() {
      this.$Progress.start()
      this.api
        .get(`/machines/list?`) //window.localStorage.getItem('userId'))
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },
    onSelect(item) {
      this.selectedId = item
      this.searchData.id = item.machineId
    },

    showAlert_ChooseOneBranch() {
      if (window.localStorage.getItem("language") == 'th') {
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      } else {
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    async getTransactions() {
      this.totalPrice = 0
      this.totalQty = 0
      this.totalAcceptorMoney = 0
      this.totalQrPayment = 0
      this.totalUseCredit = 0
      this.totalTopUpCreate = 0
      this.totalDiffCredit = 0


      this.rowData = []
      this.api({
        url: `/report/machine/transactions/${this.searchData.id}?from=${this.searchData.start}&to=${this.searchData.end}`,
        method: 'GET',
        params: this.searchData,
      })
        .then(res => {
          this.$Progress.finish()
          this.rowData = res.data.row

          // this.rowData_QR =  res.data.row_qr
          console.log('rowData', res.data)
          // console.log('befor this.rowData', this.rowData)
          console.log('this.rowData.length', this.rowData.length)

          this.totalPrice = webServices.addCommas(res.data.price)
          this.totalQty = webServices.addCommas(res.data.qty)
          this.totalAcceptorMoney = webServices.addCommas(res.data.acceptorMoney)

          for (var h = 0; h < this.rowData.length; h++) {
            this.rowData[h]['index'] = h + 1

            this.totalQrPayment = this.totalQrPayment + this.rowData[h].qrPayment
            this.totalUseCredit = this.totalUseCredit + this.rowData[h].useCredit
            this.totalTopUpCreate = this.totalTopUpCreate + this.rowData[h].topUpCreate
            this.totalDiffCredit = this.totalUseCredit - this.totalTopUpCreate
          }
          // console.log('atter this.rowData', this.rowData)
          // console.log('data map', this.rowData)
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('Error @report by machine')
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },

    searchFn(opt = { excel: false }) {
      // this.rowData = []
      this.$Progress.start()
      if (this.searchData.id === '' || this.date === '') {
        this.$toast.error({
          title: 'ERROR',
          message: 'Please select date and machine ID'
        })
      }

      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      // var start = this.date[0]
      // var end = this.date[1]

      var timeEnd = this.searchData.date[1].toLocaleTimeString()
      if (timeEnd === '00:00:00') {
        end.setHours(23, 59, 59, 999)
      }
      this.searchData.start = moment(this.searchData.date[0]).format('YYYY-MM-DD HH:mm:ss')
      this.searchData.end = moment(this.searchData.date[1]).format('YYYY-MM-DD HH:mm:ss')

      var dateStart = new Date(this.searchData.date[0])
      var dateEnd = new Date(this.searchData.date[1])


      if (this.monthDiff(dateStart, dateEnd) < 12) {
        console.log("opt.excel", opt.excel)
        if (!opt.excel) {
          this.getTransactions()
          this.data_export.date = this.searchData.date
          this.data_export.id = this.searchData.id
          console.log('this.data_export ', this.data_export)
        } else {
          console.log("this.exportExcel")
          if (this.rowData.length > 0) {

            // this.getTransactions()
            this.exportExcel()
          }
        }
      } else {
        console.log("เลือกเดือนได้สูงสุด 12 เดือน")
        this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      }

      // }
    },

    exportExcel() {
      console.log('exportExcel')
      this.$Progress.start()
      // this.searchData.rowData =  this.rowData

      for (let i = 0; i < this.machineData.length; i++) {
        if (this.searchData.id === this.machineData[i].machineId) {
          const machineName = this.machineData[i].name
          // this.searchData.machineId = this.machineData[i].machineId
          this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
        }
      }

      console.log('searchData', this.searchData)
      this.api({
        url: `/report/machine/download/${this.data_export.id}?from=${moment(this.data_export.date[0]).format('YYYY-MM-DD HH:mm:ss')}&to=${moment(this.data_export.date[1]).format('YYYY-MM-DD HH:mm:ss')}`,
        method: 'GET',
        params: this.searchData,
        responseType: 'blob'
      }).then(response => {
        FileSaver.saveAs(response.data, `Report_Income_Per_Kiosk_${this.data_export.id}_date_${moment(this.data_export.date[0]).format('YYYY-MM-DD')}_to_${moment(this.data_export.date[1]).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },

    sortFunction(type) {
      if (type === 'qty') {
        this.rowData.sort(function (a, b) {
          return a.product.name === 'สรุป' || b.product.name === 'สรุป' ? 1 : a.qty < b.qty ? 1 : b.qty < a.qty ? -1 : 0
        })
        // this.rowData.sort(function (a, b) { return (a.qty < b.qty) ? 1 : (((b.qty < a.qty) || (a.product.name === 'สรุป')) ? -1 : 0) })
      } else if (type === 'price') {
        this.rowData.sort(function (a, b) {
          return a.product.name === 'สรุป' || b.product.name === 'สรุป' ? 1 : Number(a.price) < Number(b.price) ? 1 : Number(b.price) < Number(a.price) ? -1 : 0
        })
      } else if (type === 'id') {
        this.rowData.sort(function (a, b) {
          return a.product.name === 'สรุป' || b.product.name === 'สรุป' ? 1 : a.productId > b.productId ? 1 : b.productId > a.productId ? -1 : 0
        })
      }
    },
    ExportFn(data) {
      if (this.rowData.length > 0) {
        // console.log('push sum')
        this.exportData = data
        // this.exportData.push({
        //   productId: '',
        //   product: {
        //     sku: '',
        //     name: 'สรุป'
        //   },
        //   qty: this.totalQty,
        //   price: this.totalPrice,
        //   acceptorMoney: this.totalAcceptorMoney
        // })
      }
      this.$refs.exportModal.show()
    },
    // closeExportModal() {
    //   // console.log('close')
    //   this.exportData.pop()
    //   this.$refs.exportModal.hide()
    // }
  },
  mounted() {
    this.api = axios.create({
      baseURL: this.axios.defaults.baseURL
    })

    this.api.defaults.headers.common['Authorization'] = this.api.defaults.headers.common['Authorization']

    this.getMachine()
  }
}
</script>

<style>
@import './styleRe.css';
</style>